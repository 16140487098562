import React, {useContext} from 'react';
import {LightboxLayout, LightboxProps, LightboxTheme} from './model';
import {css, CreateSheetWithTheme, useTheme, GetColor} from 'lib/aphrodite/aphrodite';
import {ReactComponent as CrossIcon} from 'src/icons/cross.svg';
import {ComponentContext} from './data';

export function Lightbox({close, styles, ...props}: LightboxProps) {
    const {theme, layout} = useContext(ComponentContext);
    const Styles = useTheme(theme, StylesWithTheme, layout);

    return (
        <article
            {...props}
            {...{
                className: css(Styles._lightbox, styles ? styles : null),
            }}
        >
            {typeof close == 'function' ? (
                <aside
                    className={css(Styles._lightboxClose)}
                    onClick={close}
                >
                    <CrossIcon
                        className={css(Styles._lightboxCloseIcon)}
                        width="14"
                        height="14"
                    />
                </aside>
            ) : null}
            {props.children}
        </article>
    );
}

export function Body({styles, ...props}: LightboxProps) {
    const {theme} = useContext(ComponentContext);
    const Styles = useTheme(theme, StylesWithTheme);

    return (
        <section
            {...props}
            {...{
                className: css(Styles._body, styles ? styles : null),
            }}
        >
            {props.children}
        </section>
    );
}

export function Header({styles, ...props}: LightboxProps) {
    const {selector, theme} = useContext(ComponentContext);
    const Styles = useTheme(theme, StylesWithTheme);

    return (
        <header
            {...props}
            {...{
                id: selector ? `_lightbox-${selector}-label` : undefined,
                className: css(Styles._header, styles ? styles : null),
            }}
        >
            {props.children}
        </header>
    );
}

export function Footer({styles, ...props}: LightboxProps) {
    const {theme} = useContext(ComponentContext);
    const Styles = useTheme(theme, StylesWithTheme);

    return (
        <footer
            {...props}
            {...{
                className: css(Styles._footer, styles ? styles : null),
            }}
        >
            {props.children}
        </footer>
    );
}

const StylesWithTheme = CreateSheetWithTheme((theme?: LightboxTheme, layout?: LightboxLayout) => {
    return {
        _lightbox: {
            borderRadius: 15,
            backgroundColor: theme?.lightbox.backgroundColor,
            color: theme?.lightbox.color,
            boxShadow: theme?.lightbox.boxShadow,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxHeight: 'calc(100vh - 80px)',
            position: 'relative',
            '@media(max-width: 640px)': layout?.fullScreenOnMobile
                ? {
                      borderRadius: 0,
                      height: 'auto',
                      maxHeight: 'initial',
                      overflow: 'hidden',
                      minHeight: '100%',
                  }
                : {},
        },
        _lightboxClose: {
            position: 'absolute',
            top: 13,
            right: 15,
            display: 'flex',
            width: 34,
            height: 34,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            cursor: 'pointer',
            transition: 'background 0.15s',
            zIndex: 2,
            ':hover': {
                background: GetColor('ButtonBgHover'),
            },
            '@media(max-width: 800px)': {
                background: GetColor('ButtonBgHover'),
            },
        },
        _lightboxCloseIcon: {
            display: 'block',
            color: theme?.lightbox.closeIconColor,
        },
        _header: {
            background: theme?.header.background,
            color: theme?.header.color,
            position: 'relative',
            fontSize: '1.25rem',
            fontWeight: 700,
            fontFamily: 'Nunito Sans',
            textAlign: 'left',
            margin: 0,
            padding: '15px 25px',
            borderRadius: '15px 15px 0 0',
            borderBottom: theme?.header.borderBottom,
            '@media(max-width: 800px)': {
                padding: '15px 20px',
                fontSize: '1.125rem',
            },
        },
        _body: {
            backgroundColor: theme?.body.backgroundColor,
            color: theme?.body.color,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
            overflow: 'auto',
            '@media(max-width: 640px)': layout?.fullScreenOnMobile
                ? {
                      overflowY: 'auto',
                  }
                : {},
        },
        _footer: {
            display: 'flex',
            padding: '15px 20px',
            justifyContent: 'flex-end',
            backgroundColor: theme?.footer.backgroundColor,
            color: theme?.footer.color,
            borderTop: theme?.footer.botderTop,
            ':nth-child(1n) button': {
                marginLeft: 10,
            },
        },
    };
});
