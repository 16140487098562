import {patch} from 'lib/fetch';
import {SessionRuntype} from 'src/runtime/session/model';

export type ProfileDetails = Partial<{
    first_name: string;
    last_name: string;
    private_profile?: boolean;
}>;

export default function UpdateProfileDetailsAPI(data: ProfileDetails) {
    return patch(
        {
            url: '/session',
            body: data,
        },
        SessionRuntype
    );
}
