import {NatsConnection, JSONCodec, Codec, headers} from './nats.ws';
import {Body} from './model';

let jc: Codec<unknown>;

async function jsonCode<T = unknown>() {
    if (!jc) {
        jc = await JSONCodec<T>();
    }
    return jc;
}

export default async function Publish<M extends object>(
    nats: NatsConnection,
    subject: string,
    body: Body<M>,
    user_jwt: string
) {
    const codec = await jsonCode();
    const h = await headers();
    h.append('jwt', user_jwt);
    h.append('uipe', UIPE);
    h.append('user-agent', window.navigator.userAgent);
    nats.publish(subject, codec.encode(body), {headers: h});
}
