import React, {useEffect, useState, startTransition} from 'react';
import {useLoaderData, Await} from 'react-router-dom';
import {PageIDs} from 'src/runtime/router/pagelist';
import {Page} from 'src/runtime/router/model';
import {listenOnSessionChanges, unlistenOnSessionChanges} from 'src/runtime/session';

export default function PageLoader<
    Payload extends object,
    Children extends Record<string, Page<any, any>> | undefined
>({page, page_id}: {page: Page<Payload, Children>; page_id: PageIDs}) {
    const [, setReload] = useState(false);

    let data: any = useLoaderData();

    const Component = page.component;

    function onSessionChange() {
        startTransition(() => {
            setReload(r => !r);
        });
    }

    useEffect(() => {
        listenOnSessionChanges(onSessionChange);
        return () => {
            unlistenOnSessionChanges(onSessionChange);
        };
    });

    return (
        <>
            <React.Suspense fallback={<Loader />}>
                {data && data.request ? (
                    <Await
                        resolve={data.request}
                        errorElement={<FetchError />}
                    >
                        {payload => {
                            return <Component payload={payload as unknown as Payload} />;
                        }}
                    </Await>
                ) : (
                    <Component payload={data as unknown as Payload} />
                )}
            </React.Suspense>
        </>
    );
}

function Loader() {
    return <></>;
}

function FetchError() {
    return <p>Error loading data!</p>;
}
