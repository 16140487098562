import {defer, createBrowserRouter, RouteObject, createMemoryRouter} from 'react-router-dom';
import ErrorPage from 'src/runtime/router/components/ErrorPage';
import {PageIDs, pagelist} from './pagelist';
import PageLoader from './components/PageLoader';
import {Page} from './model';
import RootComponent from './components/RootComponent';

function pagesToRoute(pages: Record<string, Page<any, any>>, parentKey?: string): RouteObject[] {
    let routes: RouteObject[] = [];

    Object.entries(pages).forEach(([key, page]) => {
        let loader: (() => ReturnType<typeof defer>) | undefined = undefined;
        if (page.preFetcher) {
            loader = () => defer({request: page.preFetcher!()});
        }

        let pageID = `${key}:::${parentKey ?? ''}`;

        let element = (
            <PageLoader
                page={page}
                page_id={key as PageIDs}
            />
        );

        if (Array.isArray(page.path)) {
            page.path.forEach((path, i) => {
                routes.push({
                    id: `${pageID}:::${i}`,
                    path,
                    element,
                    loader,
                    children: page.children ? pagesToRoute(page.children, `${pageID}:::${i}`) : undefined,
                });
            });
        } else {
            let route: RouteObject = {};
            if (page.path === 'INDEX') {
                route = {
                    index: true,
                };
            } else if (page.path === 'WRAPPER') {
                route = {
                    children: page.children ? pagesToRoute(page.children, pageID) : undefined,
                };
            } else {
                route = {
                    path: page.path,
                    children: page.children ? pagesToRoute(page.children, pageID) : undefined,
                };
            }

            routes.push({
                id: pageID,
                element,
                loader,
                ...route,
            });
        }
    });

    return routes;
}

let BrowserRouter: ReturnType<typeof createBrowserRouter>;

function createRouter() {
    return [
        {
            path: '/',
            element: <RootComponent />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: '*',
                    lazy: () => import('./components/Page404'),
                },
                ...pagesToRoute(pagelist),
            ],
        },
    ];
}

export function InitRouter() {
    BrowserRouter = createBrowserRouter(createRouter());
    return BrowserRouter;
}

export function InitSSRRouter(path: string) {
    console.log(path);
    BrowserRouter = createMemoryRouter(createRouter(), {
        initialEntries: [path],
        initialIndex: 0,
    });

    return BrowserRouter;
}

export {BrowserRouter};
