import {UserSessionAccessRuntype} from 'generated/AccessPolicies';
import {CompanyMetadataRuntype} from 'packages/companies/model';
import {ExternalFileRuntype} from 'packages/files/models';
import {UserMetadataRuntype, UserTypeIDRuntype} from 'packages/users/model';
import * as runtypes from 'runtypes';

export const SessionUserRuntype = runtypes.Record({
    guid: runtypes.String,
    is_verified: runtypes.Boolean,
    email: runtypes.String.Or(runtypes.Null),
    first_name: runtypes.String,
    last_name: runtypes.String,
    full_name: runtypes.String,
    user_type_id: UserTypeIDRuntype,
    private_profile: runtypes.Boolean.optional(),
    nats_user_token: runtypes.String.nullable(),
});

export const SessionExtraRuntype = runtypes.Record({
    su_login: runtypes.Optional(UserMetadataRuntype),
    access_policy: runtypes.Optional(UserSessionAccessRuntype.nullable()),
    companies: runtypes.Array(CompanyMetadataRuntype).optional(),
    user_image: runtypes.Optional(ExternalFileRuntype),
    is_jobseeker_profile_incomplete: runtypes.Boolean.optional(),
});

export const SessionRuntype = runtypes.Record({
    user: SessionUserRuntype,
    extra: SessionExtraRuntype,
});

export type SessionUser = Readonly<runtypes.Static<typeof SessionUserRuntype>>;
export type SessionExtra = Readonly<runtypes.Static<typeof SessionExtraRuntype>>;
export type Session = Readonly<runtypes.Static<typeof SessionRuntype>>;
