import {CreateSheet, GetColor, css} from 'aphrodite';
import NewsletterComponent from './NewsletterComponent';
import {useEffect, useState} from 'react';
import {ReactComponent as ArrowRightLongIcon} from 'src/icons/arrow-right-long.svg';
import {ReactComponent as NewsletterIcon} from 'src/icons/newsletter.svg';
import useMaxWidth from 'lib/hooks/useMaxWidth';
import {Events, isClosedOrPassedBefore, setIsClosedOrPassedBefore} from './events';
import useOnce from 'lib/hooks/useOnce';

const animationMS = 200;

export default function NewsletterPopup() {
    const isLessThan800 = useMaxWidth(800);
    const [status, setStatus] = useState<'closed' | 'open' | 'auto-open'>('closed');

    function onClose() {
        setStatus('closed');
        setIsClosedOrPassedBefore();
    }

    function onOpenEvent(is_auto_open?: boolean) {
        if (is_auto_open && isClosedOrPassedBefore()) {
            return;
        }

        setStatus(is_auto_open ? 'auto-open' : 'open');
    }

    useEffect(() => {
        Events.on('open-popup', onOpenEvent);

        return () => {
            Events.removeListener('open-popup', onOpenEvent);
        };
    }, []);

    if (status === 'closed') {
        return null;
    } else if (isLessThan800) {
        return (
            <NewsletterPopupMobile
                onClose={onClose}
                skipFooter={status === 'open'}
            />
        );
    } else {
        return <NewsletterPopupDesktop onClose={onClose} />;
    }
}

function NewsletterPopupDesktop({onClose}: {onClose: () => void}) {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isClosed, setIsClosed] = useState(false);

    function onCloseCallback() {
        setIsClosed(true);
        setTimeout(() => {
            onClose();
        }, animationMS);
    }

    function onImageLoaded() {
        setTimeout(() => {
            setIsImageLoaded(true);
        }, animationMS);
    }

    return (
        <aside className={css(Styles.root, Styles.rootHidden, isImageLoaded && !isClosed && Styles.rootVisible)}>
            <NewsletterComponent
                onImageLoaded={onImageLoaded}
                onClose={onCloseCallback}
            />
        </aside>
    );
}

function NewsletterPopupMobile({onClose, skipFooter}: {onClose: () => void; skipFooter: boolean}) {
    const [initialAnimationPassed, setInitialAnimationPassed] = useState(false);
    const [showPopup, setShowPopup] = useState(skipFooter ? true : false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isClosed, setIsClosed] = useState(false);

    function onCloseCallback() {
        setIsClosed(true);
        setTimeout(() => {
            onClose();
        }, animationMS);
    }

    function onImageLoaded() {
        setIsImageLoaded(true);
    }

    useOnce(() => {
        setTimeout(() => {
            setInitialAnimationPassed(true);
        }, 300);
    });

    return (
        <>
            {showPopup && initialAnimationPassed && (
                <aside
                    className={css(
                        Styles.root,
                        StylesMobile.root,
                        StylesMobile.rootHidden,
                        isImageLoaded && !isClosed && StylesMobile.rootVisible
                    )}
                >
                    <NewsletterComponent
                        onImageLoaded={onImageLoaded}
                        onClose={onCloseCallback}
                    />
                </aside>
            )}
            <aside
                className={css(
                    StylesMobile.footer,
                    !initialAnimationPassed || showPopup ? StylesMobile.footerHidden : null
                )}
            >
                <div className={css(StylesMobile.icon)}>
                    <NewsletterIcon height={30} />
                </div>
                <div className={css(StylesMobile.text)}>
                    Insights for The Modern <span className={css(StylesMobile.textHighlight)}>Beautypreneur</span>
                </div>
                <button
                    className={css(StylesMobile.open)}
                    onClick={() => setShowPopup(true)}
                >
                    <ArrowRightLongIcon className={css(StylesMobile.openIcon)} />
                </button>
            </aside>
        </>
    );
}

const Styles = CreateSheet({
    root: {
        position: 'fixed',
        top: 75,
        bottom: 25,
        right: 20,
        zIndex: 1000,
        maxWidth: 420,
        transition: `transform ${animationMS}ms`,
    },
    rootHidden: {
        transform: 'translateX(calc(100% + 50px))',
    },
    rootVisible: {
        transform: 'translateX(0)',
    },
});

const StylesMobile = CreateSheet({
    root: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        maxWidth: '100%',
        padding: 20,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: `opacity ${animationMS}ms`,
    },
    rootHidden: {
        opacity: 0,
    },
    rootVisible: {
        opacity: 1,
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        padding: '15px 20px',
        borderRadius: '18px 18px 0 0',
        boxShadow: `0px 5px 50px -5px ${GetColor('Shadow20')}`,
        border: `1px solid ${GetColor('StrokeColor')}`,
        borderBottom: 'none',
        backgroundColor: GetColor('TopLayerObjectBg'),
        display: 'flex',
        alignItems: 'center',
        transition: `transform ${animationMS}ms`,
    },
    footerHidden: {
        transform: 'translateY(calc(100% + 50px))',
    },
    icon: {
        marginRight: 15,
        height: 30,
        flexShrink: 0,
        '& svg': {
            display: 'block',
            marginTop: 3,
        },
    },
    text: {
        lineHeight: '1.2rem',
        fontWeight: 700,
        flexGrow: 1,
    },
    textHighlight: {
        color: GetColor('Accent2'),
    },
    open: {
        width: 34,
        height: 34,
        borderRadius: 17,
        backgroundColor: GetColor('ButtonBg'),
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
        marginLeft: 15,
        flexShrink: 0,
    },
    openIcon: {
        width: 14,
        height: 14,
        color: GetColor('Primary'),
    },
});
