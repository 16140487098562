import * as r from 'runtypes';

export const CompanyAccessRuntype = r.Partial({
    can_view: r.Boolean,
    can_edit: r.Boolean,
    can_delete: r.Boolean,
    can_manage_subscription: r.Boolean,
    can_view_jobs: r.Boolean,
    can_manage_jobs: r.Boolean,
    can_view_favourites: r.Boolean,
    can_manage_favourites: r.Boolean,
    can_set_default: r.Boolean,
    can_manage_members: r.Boolean,
    can_manage_applications: r.Boolean,
});
export type CompanyAccess = r.Static<typeof CompanyAccessRuntype>;

export const ConversationAccessRuntype = r.Partial({
    can_view: r.Boolean,
    can_post: r.Boolean,
});
export type ConversationAccess = r.Static<typeof ConversationAccessRuntype>;

export const JobAccessRuntype = r.Partial({
    can_view: r.Boolean,
    can_edit: r.Boolean,
    can_delete: r.Boolean,
    can_apply: r.Boolean,
    can_view_apply: r.Boolean,
    can_message: r.Boolean,
    can_view_message: r.Boolean,
    can_revoke: r.Boolean,
    can_save: r.Boolean,
    can_view_save: r.Boolean,
});
export type JobAccess = r.Static<typeof JobAccessRuntype>;

export const JobSeekerProfileAccessRuntype = r.Partial({
    can_edit: r.Boolean,
    can_view_full: r.Boolean,
    can_view_limited: r.Boolean,
    can_hire: r.Boolean,
    can_view_hire: r.Boolean,
    can_message: r.Boolean,
    can_view_message: r.Boolean,
    can_save: r.Boolean,
    can_view_save: r.Boolean,
});
export type JobSeekerProfileAccess = r.Static<typeof JobSeekerProfileAccessRuntype>;

export const ResourceAccessRuntype = r.Partial({
    can_edit: r.Boolean,
    can_delete: r.Boolean,
});
export type ResourceAccess = r.Static<typeof ResourceAccessRuntype>;

export const SiteEventAccessRuntype = r.Partial({
    can_edit: r.Boolean,
    can_delete: r.Boolean,
});
export type SiteEventAccess = r.Static<typeof SiteEventAccessRuntype>;

export const UserSessionAccessRuntype = r.Partial({
    has_console_access: r.Boolean,
    has_users_access: r.Boolean,
    can_login_as_user: r.Boolean,
    has_admins_access: r.Boolean,
    can_manage_admins: r.Boolean,
    can_manage_resources: r.Boolean,
    can_manage_site_events: r.Boolean,
    can_manage_users: r.Boolean,
    can_add_resources: r.Boolean,
    can_add_site_events: r.Boolean,
    can_subscribe_to_company_plans: r.Boolean,
    can_create_company: r.Boolean,
    can_manage_sitemaps: r.Boolean,
});
export type UserSessionAccess = r.Static<typeof UserSessionAccessRuntype>;

