import * as runtypes from 'runtypes';

export const ExternalFileRuntype = runtypes.Record({
    guid: runtypes.String,
    file_name: runtypes.String,
    url: runtypes.String,
    mime: runtypes.String,
    size: runtypes.Number,
    width: runtypes.Number,
    height: runtypes.Number,
    avg_color: runtypes.String,
    scan_status: runtypes.Number,
    created_at: runtypes.Number,
});

export type ExternalFile = runtypes.Static<typeof ExternalFileRuntype>;
