// @ts-nocheck
import calc from 'inline-style-prefixer/lib/plugins/calc';
import crossFade from 'inline-style-prefixer/lib/plugins/crossFade';
import cursor from 'inline-style-prefixer/lib/plugins/cursor';
import filter from 'inline-style-prefixer/lib/plugins/filter';
import flex from 'inline-style-prefixer/lib/plugins/flex';
import flexboxIE from 'inline-style-prefixer/lib/plugins/flexboxIE';
import flexboxOld from 'inline-style-prefixer/lib/plugins/flexboxOld';
import gradient from 'inline-style-prefixer/lib/plugins/gradient';
import grid from 'inline-style-prefixer/lib/plugins/grid';
import imageSet from 'inline-style-prefixer/lib/plugins/imageSet';
import logical from 'inline-style-prefixer/lib/plugins/logical';
import position from 'inline-style-prefixer/lib/plugins/position';
import sizing from 'inline-style-prefixer/lib/plugins/sizing';
import transition from 'inline-style-prefixer/lib/plugins/transition';
var w = ['Webkit'];
var m = ['Moz'];
var ms = ['ms'];
var wm = ['Webkit', 'Moz'];
var wms = ['Webkit', 'ms'];
var wmms = ['Webkit', 'Moz', 'ms'];

const vars = {
    plugins: [
        calc,
        crossFade,
        cursor,
        filter,
        flex,
        flexboxIE,
        flexboxOld,
        gradient,
        grid,
        imageSet,
        logical,
        position,
        sizing,
        transition,
    ],
    prefixMap: {
        transform: w,
        transformOrigin: w,
        transformOriginX: w,
        transformOriginY: w,
        backfaceVisibility: w,
        perspective: w,
        perspectiveOrigin: w,
        transformStyle: w,
        transformOriginZ: w,
        animation: w,
        animationDelay: w,
        animationDirection: w,
        animationFillMode: w,
        animationDuration: w,
        animationIterationCount: w,
        animationName: w,
        animationPlayState: w,
        animationTimingFunction: w,
        appearance: wmms,
        userSelect: wmms,
        fontKerning: w,
        textEmphasisPosition: wms,
        textEmphasis: wms,
        textEmphasisStyle: wms,
        textEmphasisColor: wms,
        boxDecorationBreak: wms,
        clipPath: w,
        maskImage: wms,
        maskMode: wms,
        maskRepeat: wms,
        maskPosition: wms,
        maskClip: wms,
        maskOrigin: wms,
        maskSize: wms,
        maskComposite: wms,
        mask: wms,
        maskBorderSource: wms,
        maskBorderMode: wms,
        maskBorderSlice: wms,
        maskBorderWidth: wms,
        maskBorderOutset: wms,
        maskBorderRepeat: wms,
        maskBorder: wms,
        maskType: wms,
        textDecorationStyle: wm,
        textDecorationSkip: wm,
        textDecorationLine: wm,
        textDecorationColor: wm,
        filter: w,
        fontFeatureSettings: wm,
        breakAfter: wmms,
        breakBefore: wmms,
        breakInside: wmms,
        columnCount: wm,
        columnFill: wm,
        columnGap: wm,
        columnRule: wm,
        columnRuleColor: wm,
        columnRuleStyle: wm,
        columnRuleWidth: wm,
        columns: wm,
        columnSpan: wm,
        columnWidth: wm,
        writingMode: w,
        flex: w,
        flexBasis: w,
        flexDirection: w,
        flexGrow: w,
        flexFlow: w,
        flexShrink: w,
        flexWrap: w,
        alignContent: w,
        alignItems: w,
        alignSelf: w,
        justifyContent: w,
        order: w,
        transitionDelay: w,
        transitionDuration: w,
        transitionProperty: w,
        transitionTimingFunction: w,
        backdropFilter: w,
        scrollSnapType: wms,
        scrollSnapPointsX: wms,
        scrollSnapPointsY: wms,
        scrollSnapDestination: wms,
        scrollSnapCoordinate: wms,
        shapeImageThreshold: w,
        shapeImageMargin: w,
        shapeImageOutside: w,
        hyphens: wmms,
        flowInto: wms,
        flowFrom: wms,
        regionFragment: wms,
        textOrientation: w,
        boxSizing: m,
        textAlignLast: m,
        tabSize: m,
        wrapFlow: ms,
        wrapThrough: ms,
        wrapMargin: ms,
        textSizeAdjust: ['ms', 'Webkit'],
        borderImage: w,
        borderImageOutset: w,
        borderImageRepeat: w,
        borderImageSlice: w,
        borderImageSource: w,
        borderImageWidth: w,
    },
};

export default vars;
