import {ExternalFileRuntype} from 'packages/files/models';
import {ServiceRuntype} from 'packages/services/model';
import * as r from 'runtypes';

export const CompanySubscriptionMetaRuntype = r.Record({
    plan_name: r.String,
    plan_marker: r.String,
    amount: r.Number,
    period_end: r.Number,
    cancel_at_period_end: r.Boolean,
    customer_email: r.String,
});

export type CompanySubscriptionMeta = r.Static<typeof CompanySubscriptionMetaRuntype>;

export const CompanyMetadataRuntype = r.Record({
    guid: r.String,
    uri: r.String,
    business_name: r.String,
    is_default: r.Boolean,
    logo_file: ExternalFileRuntype.nullable(),
    incomplete: r.Boolean.optional(),
    subscription_metadata: CompanySubscriptionMetaRuntype,
});
export type CompanyMetadata = r.Static<typeof CompanyMetadataRuntype>;

export const CompanyMemberRuntype = r.Record({
    email: r.String,
    role_id: r.String,
    status_id: r.String,
});
export type CompanyMember = r.Static<typeof CompanyMemberRuntype>;
export const CompanyMembersRuntype = r.Array(CompanyMemberRuntype);
export type CompanyMembers = r.Static<typeof CompanyMembersRuntype>;

export const InviteLinkStatusRuntype = r.Record({
    is_enabled: r.Boolean,
    link: r.String,
});

export const CompanyUserMetadataRuntype = r.Record({
    role_id: r.String,
});

export type InviteLinkStatus = r.Static<typeof InviteLinkStatusRuntype>;
export const CompanyDetailsRuntype = r.Record({
    guid: r.String,
    uri: r.String,
    business_name: r.String,
    business_address_full: r.String,
    business_address_state: r.String,
    business_address_zipcode: r.String,
    description: r.String,
    description_file: ExternalFileRuntype.Or(r.Null),
    linkedin_url: r.String,
    contact_email: r.String,
    contact_phone: r.String,
    website_url: r.String,
    ein: r.String.Or(r.Null),
    incomplete: r.Boolean,
});
export type CompanyDetails = r.Static<typeof CompanyDetailsRuntype>;
export const CompanyRuntype = CompanyDetailsRuntype.extend({
    services: r.Array(ServiceRuntype),
    description_file: ExternalFileRuntype.Or(r.Null),
    logo_file: ExternalFileRuntype.Or(r.Null),
    banner_file: ExternalFileRuntype.Or(r.Null),
    user_metadata: CompanyUserMetadataRuntype.optional().Or(r.Null),
});

export type Company = r.Static<typeof CompanyRuntype>;

export const EmptyCompany: Company = {
    guid: '',
    uri: '',
    business_name: '',
    business_address_full: '',
    business_address_state: '',
    business_address_zipcode: '',
    services: [],
    description: '',
    description_file: null,
    linkedin_url: '',
    contact_email: '',
    contact_phone: '',
    website_url: '',
    logo_file: null,
    banner_file: null,
    ein: '',
    incomplete: true,
    user_metadata: null,
};

export const BannerAspectRatio = 1120 / 300;
