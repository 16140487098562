import {useState, useEffect, useRef} from 'react';

export default function useMaxWidth(width: number): boolean {
    const mql = useRef(window.matchMedia(`(max-width: ${width}px)`));
    const [IsPassed, SetIsPassed] = useState(mql.current.matches);

    function OnChange(e: MediaQueryListEvent) {
        SetIsPassed(e.matches);
    }

    useEffect(() => {
        let mq = mql.current;
        let func = (callback: (e: MediaQueryListEvent) => void) => {
            if (typeof mq.addEventListener === 'function') {
                mq.addEventListener('change', callback);
            } else {
                mq.addListener(callback);
            }
        };
        func(OnChange);
        return () => {
            let func = (callback: (e: MediaQueryListEvent) => void) => {
                if (typeof mq.addEventListener === 'function') {
                    mq.removeEventListener('change', callback);
                } else {
                    mq.removeListener(callback);
                }
            };
            func(OnChange);
        };
    });

    return IsPassed;
}
