import {post} from 'lib/fetch';
import {SessionRuntype} from 'src/runtime/session/model';

export default function SubscribeToNewsletterAPI(email: string) {
    return post(
        {
            url: '/newsletters',
            body: {email, page_uri: window.location.pathname},
        },
        SessionRuntype
    );
}
