import {PropsWithChildren, useEffect, useLayoutEffect, useRef} from 'react';
import {createPortal} from 'react-dom';
import {StyleDeclarationValue, css} from 'aphrodite';

export default function Portal({
    styles,
    wrapperID,
    children,
}: PropsWithChildren<{styles?: StyleDeclarationValue; wrapperID?: string}>) {
    const wrapper = document.getElementById(wrapperID ?? 'modal-root') as HTMLElement;
    const el = useRef(document.createElement('div'));

    useEffect(() => {
        el.current.className = css(styles);
    }, [styles]);

    useLayoutEffect(() => {
        const element = el.current;

        if (wrapper) {
            wrapper.appendChild(element);
        }

        return () => {
            if (wrapper && wrapper.contains(element)) {
                wrapper.removeChild(element);
            }
        };
    }, [wrapper]);

    return createPortal(children, el.current);
}
