import {Codec, MsgHdrs, Subscription, Authenticator, NatsConnection, ConnectionOptions} from 'nats.ws';

async function connect(opts?: ConnectionOptions): Promise<NatsConnection> {
    const nats = await loadNats();
    return await nats.connect(opts);
}

async function JSONCodec<T = unknown>(
    reviver?: (this: unknown, key: string, value: unknown) => unknown
): Promise<Codec<T>> {
    const nats = await loadNats();
    return nats.JSONCodec<T>(reviver);
}

async function headers(code?: number, description?: string): Promise<MsgHdrs> {
    const nats = await loadNats();
    return nats.headers(code, description);
}

async function jwtAuthenticator(
    ajwt: string | (() => string),
    seed?: Uint8Array | (() => Uint8Array)
): Promise<Authenticator> {
    const nats = await loadNats();
    return nats.jwtAuthenticator(ajwt, seed);
}

async function loadNats() {
    const nats = await import(/* webpackChunkName: "nats" */ 'nats.ws');
    return nats;
}

export type {Authenticator, NatsConnection, Subscription, Codec};
export {connect, JSONCodec, headers, jwtAuthenticator};
