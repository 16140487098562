import {FetchResponseError, ResultWithError} from './model';
import {Runtype} from 'runtypes';

export default function CheckResultRuntype<A = unknown>(
    runtype: Runtype<A>,
    data: ResultWithError<A>
): ResultWithError<A> {
    if (data[0] !== null) {
        const res = runtype.validate(data[0]);
        if (!res.success) {
            console.error(`Validation error`, res.message, res.details);
            return [null, new FetchResponseError('runtime validation error')];
        }
    }

    return data;
}
