import {CreateSheet, GetColor, css} from 'aphrodite';
import {MakeButton} from 'packages/elements/button';
import {session} from 'src/runtime/session';

export default function LoggedInAsUser() {
    if (session.extra.su_login && session.extra.su_login.guid) {
        return (
            <div className={css(Styles.logged_in_as)}>
                logged in as {session.user.email}{' '}
                <button
                    {...{
                        className: MakeButton('FilledAccent2-Small', Styles.button),
                        onClick: () => {
                            window.location.href = `/console/users/${session.extra.su_login!.guid}/logout`;
                        },
                    }}
                >
                    <>switch back to {session.extra.su_login.email}</>
                </button>
            </div>
        );
    }

    return null;
}

const Styles = CreateSheet({
    button: {
        marginLeft: 10,
        padding: '2px 10px',
        borderRadius: 4,
    },
    logged_in_as: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        backgroundColor: GetColor('Accent2'),
        zIndex: 10000,
        color: 'white',
        fontSize: 12,
        padding: '5px 10px',
        borderTopLeftRadius: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 500,
        lineHeight: '12px',
        borderRight: `1px solid ${GetColor('Accent2Light')}`,
        borderBottom: `1px solid ${GetColor('Accent2Light')}`,
    },
});
