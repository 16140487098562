type Padding = {
    top: number | string;
    right: number | string;
    bottom: number | string;
    left: number | string;
};

export function isNumeric(str: string) {
    if (typeof str != 'string') return false;
    //@ts-ignore
    return !isNaN(str) && !isNaN(parseFloat(str));
}

export function AddExtraPadding(original: number | string, extra: number | string) {
    const _original = ParsePadding(original);
    const _extra = ParsePadding(extra);

    return [
        `calc(${_original.top} + ${_extra.top})`,
        `calc(${_original.right} + ${_extra.right})`,
        `calc(${_original.bottom} + ${_extra.bottom})`,
        `calc(${_original.left} + ${_extra.left})`,
    ].join(' ');
}

export function ParsePadding(padding: string | number): Padding {
    if (typeof padding === 'number') {
        padding = `${padding}px`;
    }

    let response: Padding = {top: '0px', right: '0px', bottom: '0px', left: '0px'};
    let parts = padding
        .trim()
        .split(' ')
        .map(part => {
            if (isNumeric(part)) {
                return `${part}px`;
            }

            if (part.split('px').length < 2) {
                return '0px';
            }

            return part;
        });

    if (parts.length === 1) {
        response.top = parts[0];
        response.right = parts[0];
        response.bottom = parts[0];
        response.left = parts[0];
    } else if (parts.length === 2) {
        response.top = parts[0];
        response.right = parts[1];
        response.bottom = parts[0];
        response.left = parts[1];
    } else if (parts.length === 3) {
        response.top = parts[0];
        response.right = parts[1];
        response.bottom = parts[2];
        response.left = parts[1];
    } else if (parts.length === 4) {
        response.top = parts[0];
        response.right = parts[1];
        response.bottom = parts[2];
        response.left = parts[3];
    }

    return response;
}
