import ReactDOM from 'react-dom/client';
import {RouterProvider} from 'react-router-dom';
import {InitRouter, BrowserRouter} from 'src/runtime/router/BrowserRouter';
import '../generated/colors.css';
import '../css/base.css';
import {rehydrate} from 'lib/aphrodite/aphrodite';

global.IS_SSR = false;

InitRouter();

if (typeof APHRODITE_CLASSNAMES !== 'undefined' && APHRODITE_CLASSNAMES.length > 0) {
    rehydrate(APHRODITE_CLASSNAMES);
}

if (HAS_SSR) {
    ReactDOM.hydrateRoot(document.getElementById('root') as HTMLElement, <RouterProvider router={BrowserRouter} />);
} else {
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(<RouterProvider router={BrowserRouter} />);
}
