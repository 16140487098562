/* @flow */
const MAP_EXISTS = typeof Map !== 'undefined';

export default class OrderedElements<T> {
    elements: {[key: string]: T};
    keyOrder: string[];

    constructor() {
        this.elements = {};
        this.keyOrder = [];
    }

    forEach(callback: (value: T, key: string) => void) {
        for (let i = 0; i < this.keyOrder.length; i++) {
            callback(this.elements[this.keyOrder[i]], this.keyOrder[i]);
        }
    }

    set(key: string, value: T, shouldReorder?: boolean) {
        if (!this.elements.hasOwnProperty(key)) {
            this.keyOrder.push(key);
        } else if (shouldReorder) {
            const index = this.keyOrder.indexOf(key);
            this.keyOrder.splice(index, 1);
            this.keyOrder.push(key);
        }

        if (value == null) {
            this.elements[key] = value;
            return;
        }

        if ((MAP_EXISTS && value instanceof Map) || value instanceof OrderedElements) {
            // We have found a nested Map, so we need to recurse so that all
            // of the nested objects and Maps are merged properly.
            const nested: OrderedElements<unknown> = this.elements.hasOwnProperty(key)
                ? (this.elements[key] as OrderedElements<unknown>)
                : new OrderedElements();
            value.forEach((value, key) => {
                nested.set(key, value, shouldReorder);
            });
            this.elements[key] = nested as T;
            return;
        }

        if (!Array.isArray(value) && typeof value === 'object') {
            // We have found a nested object, so we need to recurse so that all
            // of the nested objects and Maps are merged properly.
            const nested: OrderedElements<unknown> = this.elements.hasOwnProperty(key)
                ? (this.elements[key] as OrderedElements<unknown>)
                : new OrderedElements();
            const keys = Object.keys(value);
            for (let i = 0; i < keys.length; i += 1) {
                // @ts-ignore
                nested.set(keys[i], value[keys[i]], shouldReorder);
            }
            this.elements[key] = nested as T;
            return;
        }

        this.elements[key] = value;
    }

    get(key: string) {
        return this.elements[key];
    }

    has(key: string) {
        return this.elements.hasOwnProperty(key);
    }

    addStyleType(styleType: Map<string, any> | OrderedElements<any> | object) {
        if ((MAP_EXISTS && styleType instanceof Map) || styleType instanceof OrderedElements) {
            styleType.forEach((value, key) => {
                this.set(key, value, true);
            });
        } else {
            const keys = Object.keys(styleType);
            for (let i = 0; i < keys.length; i++) {
                // @ts-ignore
                this.set(keys[i], styleType[keys[i]], true);
            }
        }
    }
}
