import {Fragment, useEffect, useState} from 'react';
import {SmoothHeightBase, props} from './SmoothHeight.react';

export default function SmoothAppear(props: Omit<props, 'status'> & {ignore?: boolean; initialState?: boolean}) {
    const [status, setStatus] = useState(!!props.initialState);
    useEffect(() => {
        setStatus(true);
    }, []);

    return (
        <Fragment>
            {props.ignore ? (
                <div className={props.className}>{props.children}</div>
            ) : (
                <SmoothHeightBase
                    {...props}
                    {...{
                        height: status ? (typeof props.height !== 'undefined' ? props.height : 'auto') : 0,
                    }}
                />
            )}
        </Fragment>
    );
}
