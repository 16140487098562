import {AddBox, RemoveBox, ActiveBoxes, Events} from './data';
import {Config} from './model';
import {LightTheme} from './themes';

export type {ComponentProps, LightboxTheme} from './model';
export * from './parts.react';

/**
 * showLightbox is a function that shows react element as a lightobx
 *
 * @param {string}                                      selector                    Unique selector for each lightbox.
 * @param {ReactFunctionComponent}                      Component                   React component that will be rendered.
 * @param {content}                                     content                     Content that will be passed as props.data to Component(optinonal)
 * @param {generic}                                     C                           Type of content(optinonal).
 * @param {boolean}                                     disableAnimation            Disables show/hide animation.
 * @param {boolean}                                     prevent.closeOnESC           Prevent close on ESC press.
 * @param {boolean}                                     prevent.closeOnOverlay       Prevent close on overlay click.
 * @param {boolean}                                     prevent.closeOnPopstate      Prevent close on popstate.
 * @param {boolean}                                     prevent.internalClose        Prevent all internal close triggers.
 * @param {object}                                      styles                      list of styles that will extend default styles.
 * @param {StyleDeclarationValue}                       dom                         styles of first element in the dom of lightbox.
 * @param {StyleDeclarationValue}                       overlay                     styles for lightbox overlay.
 * @param {StyleDeclarationValue}                       wrapper                     styles for lightbox wrapper.
 * @param {boolean}                                     light                       white lightbox.
 * @param {boolean}                                     overlayClose                close button on lightbox overlay.
 * @param {object}                                      baseStyles                  list of styles that will overwrite default styles.
 * @param {StyleDeclarationValue}                       overlay                     styles for lightbox overlay.
 * @param {StyleDeclarationValue}                       wrapper                     styles for lightbox wrapper.
 *
 */

export async function showLightbox<C = never>(config: Config<C>): Promise<void> {
    new Promise((resolve, reject) => {
        if (ActiveBoxes.has(config.selector)) {
            console.error(`loader.show_lightbox: lightbox with this selector (${config.selector}) already rendered`);
            return;
        }

        if (!config.theme) {
            config.theme = LightTheme;
        }

        AddBox(config);
        Events.emit('rerender-boxes');
        document.body.style.overflow = 'hidden';

        resolve(null);
    });
}

export async function closeLightbox(selector: string, onClose?: (selector?: string) => void): Promise<void> {
    document.body.style.overflow = 'auto';

    if (typeof onClose === 'function') {
        onClose(selector);
    }

    RemoveBox(selector);
    Events.emit('rerender-boxes');

    return;
}
