/* @flow */
import {hashString} from './util';
import {
    injectAndGetClassName,
    reset,
    resetInjectedStyle,
    startBuffering,
    flushToString,
    flushToStyleTag,
    addRenderedClassNames,
    getRenderedClassNames,
} from './inject';
import {defaultSelectorHandlers} from './generate';
import {CSSProperties, CSSPropertiesComplete} from './css-properties';
import type {SelectorHandler} from './generate';

export type {CSSProperties, CSSPropertiesComplete};
export type StyleDeclarationMap = Map<keyof CSSProperties, string | number>;
export type StyleDeclaration<T = {}> = {
    [P in keyof T]: CSSProperties | StyleDeclarationMap;
};
export type StyleDeclarationValue = object;
export type CSSInputTypes = StyleDeclarationValue | false | null | void;

const unminifiedHashFn = (str: string, key: string) => `${key}_${hashString(str)}`;

// StyleSheet.create is in a hot path so we want to keep as much logic out of it
// as possible. So, we figure out which hash function to use once, and only
// switch it out via minify() as necessary.
//
// This is in an exported function to make it easier to test.
export const initialHashFn = () => (process.env.NODE_ENV === 'production' ? hashString : unminifiedHashFn);

let hashFn = initialHashFn();

const StyleSheet = {
    create<T>(sheetDefinition: StyleDeclaration<T>): {[K in keyof T]: StyleDeclarationValue} {
        const mappedSheetDefinition = {} as {[K in keyof T]: StyleDeclarationValue};
        const keys = Object.keys(sheetDefinition);

        for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            // @ts-ignore
            const val = sheetDefinition[key];
            const stringVal = JSON.stringify(val);

            // @ts-ignore
            mappedSheetDefinition[key] = {
                _len: stringVal.length,
                _name: hashFn(stringVal, key),
                _definition: val,
            };
        }

        return mappedSheetDefinition;
    },

    rehydrate(renderedClassNames: string[] = []) {
        addRenderedClassNames(renderedClassNames);
    },
};

/**
 * Utilities for using Aphrodite server-side.
 *
 * This can be minified out in client-only bundles by replacing `typeof window`
 * with `"object"`, e.g. via Webpack's DefinePlugin:
 *
 *   new webpack.DefinePlugin({
 *     "typeof window": JSON.stringify("object")
 *   })
 */

export async function renderStatic(renderFunc: () => Promise<string>) {
    reset();
    startBuffering();
    const html = await renderFunc();
    const cssContent = flushToString();

    return {
        html: html,
        css: {
            content: cssContent,
            renderedClassNames: getRenderedClassNames(),
        },
    };
}

/**
 * Generate the Aphrodite API exports, with given `selectorHandlers` and
 * `useImportant` state.
 */
export default function makeExports(
    useImportant: boolean,
    selectorHandlers: SelectorHandler[] = defaultSelectorHandlers
) {
    return {
        StyleSheet: {
            ...StyleSheet,

            /**
             * Returns a version of the exports of Aphrodite (i.e. an object
             * with `css` and `StyleSheet` properties) which have some
             * extensions included.
             */
            extend(
                extensions: {
                    selectorHandler: SelectorHandler;
                }[]
            ) {
                const extensionSelectorHandlers = extensions
                    // Pull out extensions with a selectorHandler property
                    .map(extension => extension.selectorHandler)
                    // Remove nulls (i.e. extensions without a selectorHandler property).
                    .filter(handler => handler);

                return makeExports(useImportant, selectorHandlers.concat(extensionSelectorHandlers));
            },
        },

        renderStatic,

        minify(shouldMinify: boolean) {
            hashFn = shouldMinify ? hashString : unminifiedHashFn;
        },

        css(...styleDefinitions: CSSInputTypes[]) {
            return injectAndGetClassName(useImportant, styleDefinitions, selectorHandlers);
        },

        flushToStyleTag,
        injectAndGetClassName,
        defaultSelectorHandlers,
        reset,
        resetInjectedStyle,
    };
}
