import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import path from 'path';
import {FetchResponseError, Request, RequestBase, ResultWithError} from './model';
import * as runtypes from 'runtypes';
import CheckResultRuntype from './CheckResultRuntype';
import {makeAPIHook, makeDebouncedAPIHook} from './useAPI';

export async function get<R = unknown>(cfg: RequestBase, runtype?: runtypes.Runtype<R>): Promise<ResultWithError<R>> {
    const res = await MakeCall<R>({
        ...cfg,
        method: 'GET',
    });

    return runtype ? CheckResultRuntype(runtype, res) : res;
}

export async function post<R = unknown>(cfg: RequestBase, runtype?: runtypes.Runtype<R>): Promise<ResultWithError<R>> {
    const res = await MakeCall<R>({
        ...cfg,
        method: 'POST',
    });

    return runtype ? CheckResultRuntype(runtype, res) : res;
}

export async function put<R = unknown>(cfg: RequestBase, runtype?: runtypes.Runtype<R>): Promise<ResultWithError<R>> {
    const res = await MakeCall<R>({
        ...cfg,
        method: 'PUT',
    });

    return runtype ? CheckResultRuntype(runtype, res) : res;
}

export async function patch<R = unknown>(cfg: RequestBase, runtype?: runtypes.Runtype<R>): Promise<ResultWithError<R>> {
    const res = await MakeCall<R>({
        ...cfg,
        method: 'PATCH',
    });

    return runtype ? CheckResultRuntype(runtype, res) : res;
}

export async function del<R = unknown>(cfg: RequestBase, runtype?: runtypes.Runtype<R>): Promise<ResultWithError<R>> {
    const res = await MakeCall<R>({
        ...cfg,
        method: 'DELETE',
    });

    return runtype ? CheckResultRuntype(runtype, res) : res;
}

async function MakeCall<R = unknown>(cfg: Request): Promise<ResultWithError<R>> {
    const url = cfg.remote ? cfg.url : `${document.location.origin}${path.join(`/api/v1/`, cfg.url)}`;
    let headers: Record<string, any> = {};

    if (cfg.headers) {
        headers = {...headers, ...cfg.headers};
    }

    if (!cfg.query) {
        cfg.query = {};
    }

    const config: AxiosRequestConfig = {
        method: cfg.method,
        url,
        headers,
        params: cfg.query,
        data: cfg.body,
        validateStatus: status => status >= 200 && status < 300,
    };

    try {
        const res = await axios.request<R>(config);
        return CreateResponse<R>(res);
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (
                typeof error.response?.headers['content-type'] === 'string' &&
                error.response?.headers['content-type'].toLowerCase() === 'application/json'
            ) {
                const msg = error.response.data?.status_text || 'Server response error';
                return [
                    null,
                    new FetchResponseError(
                        msg,
                        error.response.data?.status_code || 1,
                        error.response.data.payload ? error.response.data.payload : error.response.data
                    ),
                ];
            } else {
                return [null, new FetchResponseError(error.message)];
            }
        } else {
            return [null, new FetchResponseError(error as any)];
        }
    }
}

function CreateResponse<R = unknown>(res: AxiosResponse<R>, is_remote?: boolean): ResultWithError<R> {
    if (
        typeof res.headers['content-type'] != 'string' ||
        res.headers['content-type'].toLowerCase() !== 'application/json'
    ) {
        return [null, new FetchResponseError('response is not json')];
    }

    if (is_remote) {
        return [res.data, null];
    } else {
        // @ts-ignore by default server api returns response in payload key
        return [res.data.payload, null];
    }
}

export type GetListParams = {
    order_by?: string;
    order_dir?: 'ASC' | 'DESC';
    page_id?: number;
    page_size?: number;
    q?: string;
    from?: number;
    to?: number;
};

export type {ResultWithError};
export {FetchResponseError, makeDebouncedAPIHook, makeAPIHook};
