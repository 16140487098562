import {CreateSheet, CSSPropertiesComplete} from 'aphrodite';
import {Motion} from './keyframes';

export type AnimationOptions = {
    animationIterationCount?: CSSPropertiesComplete['animationIterationCount'];
    animationTimingFunction?: CSSPropertiesComplete['transitionTimingFunction'];
    animationDuration: number | {enter?: number; exit?: number};
    animatingElementSelector?: string;
};

export const CreateTransitionAnimation = (motion: Motion | undefined, options: AnimationOptions) => {
    if (!motion) {
        return undefined;
    }

    // increase css animation by 10ms to prevent flashing
    const d = options.animationDuration;
    let duration = {
        enter: typeof d === 'number' ? d + 10 : d.enter ? d.enter + 10 : 0,
        exit: typeof d === 'number' ? d + 10 : d.exit ? d.exit + 10 : 0,
    };

    let enterActive: any = {
        animationName: motion.map(m => `motion-${m}-enter`).join(','),
        animationDuration: `${duration.enter}ms`,
        animationIterationCount: options.animationIterationCount || 'unset',
        animationTimingFunction: options.animationTimingFunction || 'linear',
    };

    let exitActive: any = {
        animationName: motion.map(m => `motion-${m}-exit`).join(','),
        animationDuration: `${duration.exit}ms`,
        animationIterationCount: options.animationIterationCount || 'unset',
        animationTimingFunction: options.animationTimingFunction || 'linear',
    };

    if (options.animatingElementSelector) {
        enterActive = {
            [`:nth-child(1n) ${options.animatingElementSelector}`]: enterActive,
        };

        exitActive = {
            [`:nth-child(1n) ${options.animatingElementSelector}`]: exitActive,
        };
    }

    return CreateSheet({
        animation: {
            '&.motion-animation-enter-active': enterActive,
            '&.motion-animation-exit-active': exitActive,
        },
    }).animation;
};
