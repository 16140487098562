import EventEmitter from 'eventemitter3';
import IsLocalStorageAvailable from 'lib/helpers/IsLocalStorageAvailable';
import {useEffect} from 'react';

export const Events = new EventEmitter<{
    'open-popup': (is_auto_open?: boolean) => void;
}>();

export function openNewsletterPopup(is_auto_open?: boolean) {
    Events.emit('open-popup', is_auto_open);
}

export function useOpenNewsletterPopup() {
    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (!LOGGED_IN) {
            timeout = setTimeout(
                () => {
                    openNewsletterPopup(true);
                },
                IS_PRODUCTION ? 10000 : 1000
            );
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, []);
}

export function isClosedOrPassedBefore() {
    return IsLocalStorageAvailable() ? localStorage.getItem('newsletter-popup-status') === 'passed' : false;
}

export function setIsClosedOrPassedBefore() {
    if (IsLocalStorageAvailable()) {
        localStorage.setItem('newsletter-popup-status', 'passed');
    }
}
