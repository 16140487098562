import {useState, useEffect} from 'react';
import {NavigateOptions, Outlet, To, useLocation, useNavigate} from 'react-router-dom';
// components
import {useRouterGetActivePages} from 'src/runtime/router/hooks';
import HistoryEvents from 'src/runtime/router/HistoryEvents';
import Renderer from 'lib/lightbox/ModalRoot.react';
import {useDetachedEffect} from 'packages/useDetached';
import {pagemap} from '../pagelist';
import {LoadSession} from 'src/runtime/session';
import OnLocationChange, {initGoogleAnalytics} from './OnLocationChange';
import ToastContainer from 'packages/toasts/Container.react';
import LoggedInAsUser from 'src/jsx/console/modules/LoggedInAsUser.react';
import 'packages/motion/keyframes';
import {NewsletterPopup} from 'packages/newsletter';

export default function RootComponent() {
    const location = useLocation();
    const navigate = useNavigate();

    const [isSessionLoding, setIsSessionLoading] = useState(IS_SSR ? false : true);
    const activePages = useRouterGetActivePages();

    function onNavigateEvent(to: To | number, options?: NavigateOptions) {
        if (typeof to === 'number') {
            navigate(to);
        } else {
            navigate(to, options);
        }
    }

    useEffect(() => {
        if (activePages.length > 0) {
            const title = pagemap[activePages[activePages.length - 1].id]
                ? pagemap[activePages[activePages.length - 1].id].title
                : pagemap.landingJobSeeker.title;
            if (title) document.title = title;
        }
    }, [location.pathname, location.search, activePages]);

    useEffect(() => {
        if (!isSessionLoding) {
            OnLocationChange(location);
        }
    }, [location, isSessionLoding]);

    // useOnce(() => {
    //     if (typeof window === 'undefined' || typeof document === 'undefined') {
    //         return;
    //     }
    //     const scriptRecap = document.createElement('script');
    //     scriptRecap.src = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit';
    //     document.body.appendChild(scriptRecap);
    //     const scriptRecapOnLoad = document.createElement('script');
    //     scriptRecapOnLoad.innerHTML =
    //         'var is_grecaptcha_loaded = false;var onloadCallback = function() {is_grecaptcha_loaded = true;};';
    //     document.body.appendChild(scriptRecapOnLoad);
    // });

    useDetachedEffect(() => {
        async function init() {
            if (IS_PRODUCTION) {
                initGoogleAnalytics();
            }

            await LoadSession();
            setIsSessionLoading(false);
        }

        init();

        HistoryEvents.addListener('navigate', onNavigateEvent);
        return () => {
            HistoryEvents.removeListener('navigate', onNavigateEvent);
        };
    }, []);

    return (
        <>
            {isSessionLoding && !HAS_SSR ? (
                <div></div>
            ) : (
                <>
                    <LoggedInAsUser />
                    <Renderer />
                    <Outlet />
                    <ToastContainer />
                    {!IS_SSR ? <NewsletterPopup /> : null}
                </>
            )}
        </>
    );
}
