import {LoadButton} from 'packages/elements/button';
import TextField from 'packages/elements/textfields/TextField';
import {useEffect, useRef, useState} from 'react';
import {routing} from 'src/runtime/router';
import image from './newsletter.png';
import useOnce from 'lib/hooks/useOnce';
import {ReactComponent as CrossIcon} from 'src/icons/cross.svg';
import {CreateSheet, GetColor, css} from 'aphrodite';
import useMaxWidth from 'lib/hooks/useMaxWidth';
import SubscribeToNewsletterAPI from './api/SubscribeToNewsletterAPI';
import SmoothLine from 'packages/motion/SmoothLine.react';
import {SmoothSteper, SmoothSteperItem} from 'packages/motion/SmoothStepper.react';
import {ReactComponent as PartyIcon} from 'src/icons/party.svg';

export default function NewsletterComponent({
    onImageLoaded,
    onClose,
}: {
    onImageLoaded: () => void;
    onClose: () => void;
}) {
    const [isReady, setReady] = useState(false);
    const [email, setEmail] = useState('');
    const [isError, setError] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isSent, setSent] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);
    const isLessThan800 = useMaxWidth(800);

    function onClickSubmit() {
        if (isSending) {
            return;
        }

        if (email.trim() === '') {
            setError(true);
            return;
        } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
            setError(true);
            return;
        }

        setIsSending(true);
        setTimeout(async () => {
            await SubscribeToNewsletterAPI(email);
            setSent(true);
            setTimeout(() => {
                onClose();
            }, 5000);
        }, 1000);
    }

    function loadImage() {
        var newImg = new Image();
        newImg.onload = function (this) {
            if (imgRef.current) {
                imgRef.current.src = newImg.src;
            }
            setReady(true);
        };
        newImg.src = image;
    }

    useOnce(() => {
        loadImage();
    });

    useEffect(() => {
        setTimeout(() => {
            onImageLoaded();
        }, 100);
    }, [isReady, onImageLoaded]);

    return (
        <div className={css(Styles.root, isLessThan800 && StylesMobile.root, !isReady && Styles.notReady)}>
            <header className={css(Styles.header)}>
                <button
                    className={css(Styles.close)}
                    onClick={onClose}
                >
                    <CrossIcon className={css(Styles.closeSvg)} />
                </button>
                <img
                    alt="newsletter"
                    ref={imgRef}
                    className={css(Styles.image)}
                />
                <aside className={css(Styles.belowImage)} />
            </header>
            <div className={css(Styles.content, isLessThan800 && StylesMobile.content)}>
                <div className={css(Styles.title, isLessThan800 && StylesMobile.title)}>
                    Insights for The Modern <span className={css(Styles.titleHighlight)}>Beautypreneur</span>
                </div>
                <div className={css(Styles.description, isLessThan800 && StylesMobile.description)}>
                    Subscribe to our newsletter to keep up to date with our latest news and events.
                </div>
                <SmoothSteper
                    active={isSent ? 1 : 0}
                    duration={200}
                    motion={['fade']}
                    grow={true}
                >
                    <SmoothSteperItem>
                        <div>
                            <TextField
                                type="text"
                                label="Email"
                                labelHidden={true}
                                placeholder="Enter your email"
                                value={email}
                                onChange={e => {
                                    setError(false);
                                    setEmail(e.target.value);
                                }}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        onClickSubmit();
                                    }
                                }}
                                error={isError}
                                styles={{
                                    element: Styles.input,
                                }}
                            />
                            <SmoothLine className={css(Styles.inputError)}>
                                {isError ? 'Invalid email address' : undefined}
                            </SmoothLine>
                            <LoadButton
                                loading={isSending}
                                variant="FilledAccent2-Big"
                                styles={[Styles.button]}
                                onClick={onClickSubmit}
                            >
                                Subscribe now
                            </LoadButton>
                            <footer className={css(Styles.footer)}>
                                By subscribing you consent to receiving marketing by email and acknowledge you have read
                                our{' '}
                                <a
                                    href="/privacy"
                                    target="_blank"
                                    onClick={routing.OpenPage('/privacy')}
                                    className={css(Styles.link)}
                                >
                                    Privacy Policy
                                </a>
                                . Unsubscribe anytime at the bottom of our emails
                            </footer>
                        </div>
                    </SmoothSteperItem>
                    <SmoothSteperItem>
                        <div className={css(Styles.doneContainer)}>
                            <div className={css(Styles.doneIcon)}>
                                <PartyIcon
                                    width={34}
                                    height={34}
                                />
                            </div>
                            <div className={css(Styles.doneTitle)}>Thank you for subscribing!</div>
                            <div className={css(Styles.doneDescription)}>
                                You've successfully signed up for our newsletter.Expect the latest news and updates in
                                your inbox soon!
                            </div>
                        </div>
                    </SmoothSteperItem>
                </SmoothSteper>
            </div>
        </div>
    );
}

const Styles = CreateSheet({
    notReady: {
        visibility: 'hidden',
    },
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: `0px 5px 50px -5px ${GetColor('Shadow20')}`,
        borderRadius: 24,
        border: `1px solid ${GetColor('StrokeColor')}`,
    },
    header: {
        flexGrow: 1,
        minHeight: 0,
        position: 'relative',
    },
    close: {
        width: 34,
        height: 34,
        borderRadius: 17,
        backgroundColor: GetColor('ButtonBg'),
        position: 'absolute',
        top: 10,
        right: 10,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
    },
    closeSvg: {
        width: 14,
        height: 14,
        color: GetColor('Primary'),
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        borderRadius: '24px 24px 0 0',
        objectPosition: '50% 30%',
        backgroundColor: GetColor('TopLayerObjectBg'),
    },
    belowImage: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: 24,
        borderRadius: '24px 24px 0 0',
        backgroundColor: GetColor('TopLayerObjectBg'),
    },
    content: {
        padding: '0 30px 30px 30px',
        backgroundColor: GetColor('TopLayerObjectBg'),
        borderRadius: '0 0 24px 24px',
    },
    title: {
        fontSize: '1.875rem',
        fontWeight: 700,
        lineHeight: '2.25rem',
    },
    titleHighlight: {
        color: GetColor('Accent2'),
    },
    description: {
        marginTop: 12,
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: '1.75rem',
        marginBottom: 20,
    },
    input: {
        width: '100%',
    },
    inputError: {
        fontSize: '.8125rem',
        lineHeight: '.8125rem',
        color: GetColor('Danger'),
        fontWeight: 600,
        paddingTop: 4,
    },
    button: {
        width: '100%',
        marginTop: 12,
    },
    footer: {
        marginTop: 15,
        fontSize: '0.813rem',
        fontWeight: 600,
        lineHeight: '1.138rem',
        color: GetColor('Secondary'),
    },
    link: {
        color: GetColor('Primary'),
        fontWeight: 700,
    },
    doneContainer: {
        paddingTop: 14,
        paddingBottom: 20,
    },
    doneIcon: {
        marginBottom: 10,
    },
    doneTitle: {
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: '1.4rem',
        paddingBottom: 5,
    },
    doneDescription: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '1.4rem',
    },
});

const StylesMobile = CreateSheet({
    root: {
        border: 'none',
        maxHeight: 700,
    },
    title: {
        fontSize: '1.5rem',
        lineHeight: '1.8rem',
    },
    description: {
        fontSize: '1rem',
        lineHeight: '1.4rem',
    },
    content: {
        padding: '0 20px 20px 20px',
    },
});
