import {StyleDeclaration, CreateSheet, GetColor, GeneratedStyles} from 'aphrodite';
import {css, CSSProperties} from 'aphrodite';

type ButtonStateProps = {
    background: CSSProperties['background'];
    color: CSSProperties['color'];
    boxShadow: CSSProperties['boxShadow'];
    opacity?: CSSProperties['opacity'];
    border?: CSSProperties['border'];
};

export type ButtonTheme = {
    plain: ButtonStateProps;
    hover?: ButtonStateProps;
    disabled?: ButtonStateProps;
};

export type ButtonLayout = {
    padding: CSSProperties['padding'];
    borderRadius: CSSProperties['borderRadius'];
    fontSize: CSSProperties['fontSize'];
    gap: CSSProperties['gap'];
    lineHeight: CSSProperties['lineHeight'];
    fontWeight: CSSProperties['fontWeight'];
    iconSize?: CSSProperties['width'];
    minHeight?: CSSProperties['minHeight'];
};

export const FilledAccent1: ButtonTheme = {
    plain: {
        background: GetColor('Accent1'),
        color: GetColor('TextColor'),
        boxShadow: undefined,
    },
    hover: {
        background: GetColor('Accent1Hover'),
        color: GetColor('TextColor'),
        boxShadow: undefined,
    },
    disabled: {
        background: GetColor('Accent1'),
        color: GetColor('TextColor'),
        boxShadow: undefined,
    },
};

export const FilledWhite: ButtonTheme = {
    plain: {
        background: GetColor('TopLayerObjectBg'),
        color: GetColor('Primary'),
        boxShadow: undefined,
    },
    hover: {
        background: GetColor('ButtonBgHover'),
        color: GetColor('Primary'),
        boxShadow: undefined,
    },
    disabled: {
        background: GetColor('TopLayerObjectBg'),
        color: GetColor('Primary'),
        boxShadow: undefined,
    },
};

export const FilledAccent2: ButtonTheme = {
    plain: {
        background: GetColor('Accent2'),
        color: GetColor('WhiteTextColor'),
        boxShadow: undefined,
    },
    hover: {
        background: GetColor('Accent2Hover'),
        color: GetColor('WhiteTextColor'),
        boxShadow: undefined,
    },
    disabled: {
        background: GetColor('Accent2'),
        color: GetColor('WhiteTextColor'),
        boxShadow: undefined,
    },
};

export const Outlined: ButtonTheme = {
    plain: {
        background: GetColor('PageBg'),
        color: GetColor('TextColor'),
        boxShadow: `inset 0 0 0 2px ${GetColor('StrokeColor')}`,
    },
    hover: {
        background: GetColor('PageBg'),
        color: GetColor('TextColor'),
        boxShadow: `inset 0 0 0 3px ${GetColor('StrokeColor')}`,
    },
    disabled: {
        background: GetColor('PageBg'),
        color: GetColor('TextColor'),
        boxShadow: `inset 0 0 0 2px ${GetColor('StrokeColor')}`,
    },
};

export const OutlinedAccent1: ButtonTheme = {
    plain: {
        background: GetColor('PageBg'),
        color: GetColor('TextColor'),
        boxShadow: `inset 0 0 0 2px ${GetColor('Accent1')}`,
    },
    hover: {
        background: GetColor('PageBg'),
        color: GetColor('TextColor'),
        boxShadow: `inset 0 0 0 3px ${GetColor('Accent1')}`,
    },
    disabled: {
        background: GetColor('PageBg'),
        color: GetColor('TextColor'),
        boxShadow: `inset 0 0 0 2px ${GetColor('Accent1')}`,
    },
};

export const OutlinedDanger: ButtonTheme = {
    plain: {
        background: GetColor('PageBg'),
        color: GetColor('Danger'),
        boxShadow: `inset 0 0 0 2px ${GetColor('StrokeColor')}`,
    },
    hover: {
        background: GetColor('PageBg'),
        color: GetColor('Danger'),
        boxShadow: `inset 0 0 0 3px ${GetColor('StrokeColor')}`,
    },
    disabled: {
        background: GetColor('PageBg'),
        color: GetColor('TextColor'),
        boxShadow: `inset 0 0 0 2px ${GetColor('StrokeColor')}`,
    },
};

export const Flat: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: GetColor('TextColor'),
        boxShadow: undefined,
    },
    hover: {
        background: 'transparent',
        color: GetColor('TextColor'),
        boxShadow: undefined,
        opacity: 0.7,
    },
    disabled: {
        background: 'transparent',
        color: GetColor('TextColor'),
        boxShadow: undefined,
    },
};

export const Transparent: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: GetColor('Primary'),
        boxShadow: undefined,
    },
    hover: {
        background: GetColor('ButtonBgHover'),
        color: GetColor('Primary'),
        boxShadow: undefined,
    },
    disabled: {
        background: 'transparent',
        color: GetColor('Primary'),
        boxShadow: undefined,
    },
};

export const FilledGray: ButtonTheme = {
    plain: {
        background: GetColor('ButtonBg'),
        color: GetColor('Primary'),
        boxShadow: undefined,
    },
    hover: {
        background: GetColor('ButtonBgHover'),
        color: GetColor('Primary'),
        boxShadow: undefined,
    },
    disabled: {
        background: GetColor('ButtonBg'),
        color: GetColor('Primary'),
        boxShadow: undefined,
    },
};

export const FilledAccent1Light: ButtonTheme = {
    plain: {
        background: GetColor('Accent1Light'),
        color: GetColor('Accent1Text'),
        boxShadow: undefined,
    },
    hover: {
        background: GetColor('Accent1Light'),
        color: GetColor('Accent1Text'),
        boxShadow: undefined,
        opacity: 0.7,
    },
    disabled: {
        background: GetColor('Accent1Light'),
        color: GetColor('Accent1Text'),
        boxShadow: undefined,
    },
};

export const FilledDanger: ButtonTheme = {
    plain: {
        background: GetColor('Danger'),
        color: GetColor('WhiteTextColor'),
        boxShadow: undefined,
    },
    hover: {
        background: GetColor('DangerHover'),
        color: GetColor('WhiteTextColor'),
        boxShadow: undefined,
    },
    disabled: {
        background: GetColor('Danger'),
        color: GetColor('WhiteTextColor'),
        boxShadow: undefined,
    },
};

export const FilledAccent2Light: ButtonTheme = {
    plain: {
        background: GetColor('Accent2Light'),
        color: GetColor('Accent2Text'),
        boxShadow: undefined,
    },
    hover: {
        background: GetColor('Accent2Light'),
        color: GetColor('Accent2Text'),
        boxShadow: undefined,
        opacity: 0.7,
    },
    disabled: {
        background: GetColor('Accent2Light'),
        color: GetColor('Accent2Text'),
        boxShadow: undefined,
    },
};

export const Link: ButtonTheme = {
    plain: {
        background: GetColor('TopLayerObjectBg'),
        color: GetColor('Primary'),
        boxShadow: undefined,
        border: `1px solid ${GetColor('StrokeColor')}`,
    },
    hover: {
        background: GetColor('ButtonBg'),
        color: GetColor('Primary'),
        boxShadow: undefined,
        border: `1px solid ${GetColor('StrokeColor')}`,
    },
    disabled: {
        background: GetColor('TopLayerObjectBg'),
        color: GetColor('Primary'),
        boxShadow: undefined,
        border: `1px solid ${GetColor('StrokeColor')}`,
        opacity: 0.7,
    },
};

export const DefaultLayout: ButtonLayout = {
    padding: '12px 16px',
    borderRadius: 8,
    fontSize: '0.875rem',
    gap: 8,
    lineHeight: '1rem',
    fontWeight: 700,
};

export const FlatLayout: ButtonLayout = {
    padding: 0,
    fontSize: '1rem',
    lineHeight: '1.125rem',
    gap: 10,
    fontWeight: 700,
    borderRadius: 0,
};

export const BigLayout: ButtonLayout = {
    padding: '15px 24px',
    borderRadius: 8,
    fontSize: '1rem',
    gap: 10,
    fontWeight: 700,
    lineHeight: '1.125rem',
    iconSize: '1.125rem',
};

export const SmallLayout: ButtonLayout = {
    padding: 8,
    borderRadius: 8,
    gap: 8,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontWeight: 700,
};

export const LabelLayout: ButtonLayout = {
    padding: '5px 20px',
    gap: 12,
    borderRadius: 50,
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: 'normal',
    minHeight: 40,
};

export const LinkLayout: ButtonLayout = {
    padding: '10px 15px',
    borderRadius: 8,
    fontSize: '1.125rem',
    gap: 10,
    fontWeight: 700,
    lineHeight: '1.125rem',
    iconSize: '1.125rem',
};

export const ThemesMap = {
    FilledAccent1: FilledAccent1,
    FilledWhite: FilledWhite,
    FilledAccent2: FilledAccent2,
    Outlined: Outlined,
    OutlinedAccent1: OutlinedAccent1,
    Flat: Flat,
    Transparent: Transparent,
    FilledGray: FilledGray,
    FilledAccent1Light: FilledAccent1Light,
    FilledAccent2Light: FilledAccent2Light,
    FilledDanger: FilledDanger,
    OutlinedDanger: OutlinedDanger,
    Link: Link,
} as const;

export const LayoutsMap = {
    Default: DefaultLayout,
    Flat: FlatLayout,
    Big: BigLayout,
    Small: SmallLayout,
    Label: LabelLayout,
    Link: LinkLayout,
} as const;

export type ButtonThemeName = keyof typeof ThemesMap;
export type ButtonLayoutName = keyof typeof LayoutsMap;

export type ButtonVariant = `${ButtonThemeName}-${ButtonLayoutName}`;

export type ButtonStyleType = GeneratedStyles | undefined | false | null;
export function MakeButton(theme: ButtonTheme, layout: ButtonLayout, ...rest: ButtonStyleType[]): string;
export function MakeButton(Variant: ButtonVariant, ...rest: ButtonStyleType[]): string;

export function MakeButton(
    themeOrVariant: ButtonTheme | ButtonVariant,
    layoutOrStyle: ButtonLayout | ButtonStyleType,
    ...rest: ButtonStyleType[]
): string {
    if (typeof themeOrVariant === 'string') {
        const [themeName, layoutName] = themeOrVariant.split('-') as [ButtonThemeName, ButtonLayoutName];
        return MakeButton(
            ThemesMap[themeName],
            LayoutsMap[layoutName],
            ...([layoutOrStyle, ...rest] as GeneratedStyles[])
        );
    }

    const theme = themeOrVariant;
    const layout = layoutOrStyle as ButtonLayout;

    let decl: StyleDeclaration = {
        border: 'none',
        display: 'inline-flex',
        fontFamily: "'Nunito Sans', sans-serif",
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        transition:
            'background .15s ease-in, color .15s ease-in, width .15s ease-in, opacity .15s ease-in, box-shadow .15s ease-in',
        userSelect: 'none',
        outline: 0,
        textDecoration: 'none',
        ...theme.plain,
        ...layout,
        ':nth-child(1n) svg': {
            width: layout.iconSize ?? '1rem',
            height: layout.iconSize ?? '1rem',
        },
        ':disabled': {
            ...theme.disabled,
            opacity: 0.5,
            cursor: 'default',
        },
    };

    if (theme.hover) {
        decl = {
            ...decl,
            ...{
                ':hover:not(:disabled)': {
                    ...theme.hover,
                },
                ':focus-visible': {
                    ...theme.hover,
                },
            },
        };
    }

    return css(CreateSheet({base: decl}).base, ...rest);
}
