export class FetchResponseError<Payload = any> extends Error {
    status_code: number;
    payload?: Payload;

    constructor(message: string, status_code?: number, payload?: Payload) {
        super(message);
        this.status_code = status_code || 1;
        this.payload = payload;
        this.name = 'FetchResponseError';
    }
}

export interface RequestBase {
    url: string;
    remote?: boolean;
    headers?: Record<string, any>;
    query?: Record<string, any>;
    body?: Record<string, any>;
}

export interface Request extends RequestBase {
    method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
}

export type ResultWithError<R> = [R, null] | [null, FetchResponseError];
