import {NatsConnection, JSONCodec, Codec, headers} from './nats.ws';
import {v4 as uuidv4} from 'uuid';
import {Body} from './model';

let jc: Codec<unknown>;

async function jsonCode<T = unknown>() {
    if (!jc) {
        jc = await JSONCodec<T>();
    }
    return jc;
}

export default async function Request<In extends object, Out extends object | void>(
    nats: NatsConnection,
    subject: string,
    reply_subject: string,
    body: Body<In>,
    user_jwt: string
): Promise<Body<Out>> {
    return new Promise<Body<Out>>(async (resolve, reject) => {
        // bug in nats.ws, if noMux - timeout doesn't work
        const timer = setTimeout(() => {
            resolve({
                marker: body.marker,
                payload: null as any,
                error: 'TIMEOUT',
            });
        }, 60000);

        try {
            const h = await headers();
            h.append('jwt', user_jwt);

            const codec = await jsonCode();
            const res = await nats.request(subject, codec.encode(body), {
                timeout: 60000,
                noMux: true,
                reply: `${reply_subject}.${uuidv4().replace(/-/g, '').toUpperCase()}`,
                headers: h,
            });
            clearTimeout(timer);
            const jcr = await JSONCodec<Body<Out>>();

            resolve(jcr.decode(res.data));
        } catch (error: any) {
            console.error(error);
            clearTimeout(timer);
            const paylod: any = null;
            resolve({
                marker: body.marker,
                payload: paylod,
                error: error.code,
            });
        }
    });
}
