import {flushToStyleTag} from 'aphrodite';
import {Fragment, PropsWithChildren, useEffect, useRef} from 'react';
import AnimateHeight, {Height} from 'react-animate-height';

export type props = PropsWithChildren<{
    duration?: number;
    height?: Height;
    onClick?: (e: React.MouseEvent) => void;
    className?: string;
    onGetNewHeight?: (height: Height) => void;
}>;

export function SmoothHeightBase({onGetNewHeight, children, duration, height, className, onClick}: props) {
    return (
        <AnimateHeight
            {...{
                height: typeof height !== 'undefined' ? height : 'auto',
                duration: typeof duration !== 'undefined' ? duration : 300,
                className,
                onClick,
                onHeightAnimationEnd: newHeight => {
                    onGetNewHeight && onGetNewHeight(newHeight);
                },
            }}
        >
            {children}
        </AnimateHeight>
    );
}

export function SmoothHeight({ignore, ...props}: props & {ignore: boolean}) {
    const ref = useRef<HTMLDivElement>(null);
    const ogn = useRef(props.onGetNewHeight);
    ogn.current = props.onGetNewHeight;

    useEffect(() => {
        if (ignore && ref.current && ogn.current) {
            flushToStyleTag();
            ogn.current(ref.current.offsetHeight);
        }
    }, [ignore]);

    return (
        <Fragment>
            {ignore ? (
                <div
                    ref={ref}
                    className={props.className}
                >
                    {props.children}
                </div>
            ) : (
                <SmoothHeightBase {...props} />
            )}
        </Fragment>
    );
}
