import HistoryEvents from './HistoryEvents';
import {Path} from 'react-router-dom';

const routing = {
    __current_path: window.location.pathname + window.location.search,
    __prev_path: window.location.pathname + window.location.search,
    push: (path: string | Partial<Path> | number, state?: any) => {
        if (typeof path !== 'string' && typeof path !== 'number') {
            if (!path.pathname) {
                path.pathname = window.location.pathname;
            }
        }

        HistoryEvents.emit('navigate', path === '' ? '/' : path, state ? {state: state} : undefined);
    },
    replace: (path: string | Partial<Path>, state?: any) => {
        if (typeof path !== 'string') {
            if (!path.pathname) {
                path.pathname = window.location.pathname;
            }
        }

        HistoryEvents.emit('navigate', path === '' ? '/' : path, {replace: true, state});
    },
    OpenPage: (path: string | Partial<Path> | number) => (e?: React.MouseEvent) => {
        e?.preventDefault();
        HistoryEvents.emit('navigate', path === '' ? '/' : path, undefined);
        window.scrollTo(0, 0);
    },
    OpenPricingWithReturn: (e?: React.MouseEvent) => {
        e?.preventDefault();
        HistoryEvents.emit('navigate', '/pricing?return_url=' + window.location.pathname, undefined);
        window.scrollTo(0, 0);
    },
    NewWindow: (path: string) => (e?: React.MouseEvent) => {
        e?.preventDefault();
        window.open(path === '' ? '/' : path, '_blank');
    },
    Back: () => {
        window.history.back();
    },
    SetMetaTags: (title: string, description: string) => {
        document.title = title;

        let metaDescription = document.querySelector('meta[name="description"]');
        if (!metaDescription) {
            metaDescription = document.createElement('meta');
            metaDescription.setAttribute('name', 'description');
            document.head.appendChild(metaDescription);
        }

        metaDescription.setAttribute('content', description);
    },
};

export {routing};
