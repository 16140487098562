import {
    renderStatic,
    CSSProperties,
    CSSPropertiesComplete,
    StyleDeclarationValue,
    StyleSheet as OriginalScheet,
    StyleDeclaration,
    flushToStyleTag,
} from './src/index';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import flattenDeep from 'lodash/flattenDeep';
import {ThemeColors} from 'generated/ThemeColors';
import isMobile from 'is-mobile';

const {StyleSheet, css} = OriginalScheet.extend([
    {
        //@ts-ignore
        selectorHandler: (selector, baseSelector, generateSubtreeStyles) => {
            let nestedTags: string[] = [];
            const selectors = selector.split(',');
            each(selectors, (subselector, key) => {
                if (selector[0] === '&') {
                    const tag = key === 0 ? subselector.slice(1) : subselector;

                    const nestedTag = generateSubtreeStyles(`${baseSelector}${tag}`.replace(/ +(?= )/g, ''));
                    if (Array.isArray(nestedTag)) {
                        nestedTags = [...nestedTags, ...nestedTag];
                    } else {
                        nestedTags.push(nestedTag);
                    }
                }
            });

            return isEmpty(nestedTags) ? null : flattenDeep(nestedTags);
        },
    },
]);

type CreateWithThemeFunc<T, L, V> = (theme?: T, layout?: L) => StyleDeclaration<V>;

export function useTheme<T, L, V>(theme: T | undefined, styles: CreateWithThemeFunc<T, L, V>, layout?: L | undefined) {
    //const ref = useRef(StyleSheet.create(styles(theme)));
    /* here we can check prev and current them and update styles if it will be needed later */
    return StyleSheet.create(styles(theme, layout));
}

export function CreateSheetWithTheme<T, L, V>(func: CreateWithThemeFunc<T, L, V>) {
    return func;
}

export type GeneratedStyles = {
    _definition: CSSProperties;
    _len: number;
    _name: string;
};

export function CreateSheet<V>(styles: StyleDeclaration<V>): {
    [K in keyof V]: GeneratedStyles;
} {
    return StyleSheet.create(styles) as {[K in keyof V]: GeneratedStyles};
}

export function GetColor(color: keyof ThemeColors) {
    return `var(--bb-color-${color})`;
}

export function ViewportHeight() {
    return !isMobile() ? '100vh' : '-webkit-fill-available';
}

const rehydrate = StyleSheet.rehydrate;

export {css, flushToStyleTag, renderStatic, OriginalScheet, rehydrate};
export type {CSSProperties, CSSPropertiesComplete, StyleDeclarationValue, StyleDeclaration};
